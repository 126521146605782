:root {
  /* Define color scheme based on the uploaded image */
  --dark-purple: #2d002e; /* Background color */
  --neon-green: #39ff14; /* Accent color (e.g., for buttons) */
  --light-pink: #ff80ed; /* For highlights or active elements */
  --dim-gray: #555; /* For less emphasized elements */
  --bright-pink: #ff007f; /* Bright pink color for important text */
}

@font-face {
  font-family: 'Hyperwave';
  src: url('./assets/fonts/Hyperwave.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Hyperwave', sans-serif;
  background-color: var(--dark-purple); /* Use the dark purple background */
  color: white; /* Set default text color to white for better contrast */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear;
}

.App-header {
  background-color: var(--dark-purple); /* Use the dark purple background */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--neon-green); /* Set header text to light pink */
}

.App-link {
  color: var(--light-pink); /* Use the neon green color for links */
}

/* grid */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px; /* Adjust the spacing between cards as needed */
  padding: 16px;
}

.card-item {
  border: 2px solid var(--neon-green); /* Adjust the width to your liking */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 0px 10px rgba(57, 255, 20, 0.5); /* Subtle neon glow */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  margin-bottom: 16px;
}

.card-item:hover {
  transform: scale(1.05); /* Slight scale on hover for interaction */
}

/* search bar */

.search-bar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Adjust the space below the search bar */
}

.search-bar-container .ant-input-affix-wrapper {
  width: 50%; /* Set the width of the search bar */
  max-width: 300px; /* Set the maximum width of the search bar */
}

/* Remove the media query for prefers-reduced-motion since it's not relevant here */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Overriding Ant Design default styles with custom colors */
/* You may need to use !important to override the styles due to specificity issues */
.ant-btn-primary {
  background-color: var(--neon-green); /* neon green */
  border-color: var(--neon-green); /* neon green */
  color: var(--dark-purple) !important; /* dark purple */
}

/* Hover state */
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--light-pink) !important; /* light pink */
  border-color: var(--light-pink) !important; /* light pink */
  color: var(--dark-purple) !important; /* dark purple */
}

/* Other states if needed */
.ant-btn-primary:active {
  color: #fff;
  background-color: var(--dark-purple);
  border-color: var(--dark-purple);
}

.ant-card {
  font-family: sans-serif;
  background-color: var(--neon-green);
  border: 1px solid var(--neon-green); /* Neon green border */
  box-shadow: 0 4px 8px 0 rgba(57, 255, 20, 0.2); /* Neon glow effect */
  color: var(--neon-green); /* Neon green text */
}

.ant-card:hover {
  box-shadow: 0 0 11px var(--neon-green); /* Enhanced glow effect on hover */
}

.ant-card-meta-title {
  font-family: sans-serif;
  color: var(--bright-pink); /* Bright pink color for important text */
  text-transform: uppercase; /* Optionally make the title uppercase for a more thematic look */
}

.clickable {
  cursor: pointer;
}

/* Text color */
h1,
h2,
h3,
.ant-card-meta-title {
  color: var(--neon-green);
}

/* Input focus state */
.ant-input:focus {
  border-color: var(--light-pink);
  box-shadow: 0 0 0 2px rgba(255, 128, 237, 0.2); /* light-pink shadow for glow */
}

/* Button hover state */
.ant-btn:hover {
  background-color: var(--light-pink);
  border-color: var(--light-pink);
}

/* Borders for elements */
.card-item {
  border: 1px solid var(--neon-green);
}

/* Feedback Messages */
.success-message {
  color: var(--neon-green);
}

.error-message {
  color: var(--bright-pink);
}

/* Larger card for the queried Kong */
.queried-kong-card {
  width: 250px; /* Set a larger width for the main queried Kong card */
}

/* Smaller cards for babies/parents */
.baby-parent-card {
  width: 150px; /* Set a smaller width for baby/parent Kong cards */
  font-size: 0.8em; /* Optionally adjust the font size to scale with the card */
}

/* Adjust image sizes within cards if necessary */
.queried-kong-card img,
.baby-parent-card img {
  width: 100%; /* Ensure the image scales to the width of the card */
  height: auto; /* Maintain aspect ratio */
}
